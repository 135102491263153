import { Row, Col, Image } from 'antd';
import { Link } from 'react-router-dom';
function Contact(props) {
  return (
    <Row ref={props.Contact} style={{ padding: "5vw" }} justify="space-around">
    <Col md={10} xs={24} sm={24} >
    <Image
            style={{marginTop: "1vw",borderBottomRightRadius: 50, borderTopLeftRadius: 50}}
            width={"100%"}
            src={require('../assets/group-pic.jpg')}
        />
    </Col>
    <Col md={10} xs={24} sm={24} style={{marginTop: 10}}>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "4vw", justifyContent:'left',alignItems:'left', color: "#ff7e20", marginBottom: "3vw"}}>Come Join Us</span>
    <span style={{display: 'flex', fontWeight: 'bold', fontSize: "2vw", justifyContent:'left',alignItems:'center', color: "#ff7e20"}}>Address</span>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "2vw", justifyContent:'left',alignItems:'center' , color: "#637b89"}}>DEYA Sports Village, Mambakkam</span>
    <span style={{display: 'flex', fontWeight: 'bold', fontSize: "2vw", justifyContent:'left',alignItems:'center', color: "#ff7e20"}}>Email Address</span>
    <span style={{display: 'flex', fontWeight: 'bolder', fontSize: "2vw", justifyContent:'left',alignItems:'center' , color: "#637b89"}}>deyasports@gmail.com</span>
    <span style={{display: 'flex', fontWeight: 'bold', fontSize: "2vw", justifyContent:'left',alignItems:'center', color: "#ff7e20"}}>Phone Number</span>
    <Link to="https://wa.me/message/7ABHFJCRR6ZCP1"><span style={{display: 'flex', fontWeight: 'bolder', fontSize: "2vw", justifyContent:'left',alignItems:'center' , color: "#637b89"}}>+91 98841 32440</span></Link>
    <Row style={{marginTop: 10}} align="top">
      <Col md={12} xs={24} sm={24} style={{alignItems:'center'}}><Link to="https://goo.gl/maps/SbVH6ZSLiNz8X7R29"><img src={require('../assets/map_icon.png')} width={"20vw"} /> <span style={{ fontWeight: 'bolder', fontSize: "2vw", justifyContent:'left',alignItems:'center' , color: "#637b89"}}>@Mambakkam</span></Link></Col>
      <Col md={12} xs={24} sm={24}><Link to="https://www.instagram.com/deyasports/"><img src={require('../assets/insta.jpg')} width={"20vw"}/><span style={{ fontWeight: 'bolder', fontSize: "2vw", justifyContent:'left',alignItems:'center' , color: "#637b89"}}> @deyasports</span></Link></Col>
    </Row>
    </Col>
       
    </Row>

  );
}

export default Contact;
